import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Tab } from '@headlessui/react';
import FormTextInput from '../Form/FormTextInput';
import Modal from '../Modal/Modal';

const ProductEditModal = ({
                              open, onClose, updateFunction
                          }) => {
    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const formikUpdate = true;
    const [formikInitialValues, setFormikInitialValues] = useState({
        product: '',
        productFamilyId: '',
        standartThicknessImperial: '',
        averageThicknessImperial: '',
        standartThicknessMetric: '',
        averageThicknessMetric: '',
        tVisible: '',
        tSolar: '',
        tUv: '',
        visOut: '',
        visIn: '',
        visSolar: '',
        uValueWinterImperial: '',
        uValueWinterMetric: '',
        uValueSummerImperial: '',
        uValueSummerMetric: '',
        sc: '',
        shgc: '',
        rhgImperial: '',
        rhgMetric: '',
        shortDescription: '',
        lsg: '',
    });

    const validationSchema = Yup.object().shape({
        product: Yup.string().required('Field Required'),
        productFamilyId: Yup.string().required('Field Required'),
        standartThicknessImperial: Yup.number().required('Field Required'),
        averageThicknessImperial: Yup.number().required('Field Required'),
        standartThicknessMetric: Yup.number().required('Field Required'),
        averageThicknessMetric: Yup.number().required('Field Required'),
        tVisible: Yup.number().required('Field Required'),
        tSolar: Yup.number().required('Field Required'),
        tUv: Yup.number().required('Field Required'),
        visOut: Yup.number().required('Field Required'),
        visIn: Yup.number().required('Field Required'),
        visSolar: Yup.number().required('Field Required'),
        uValueWinterImperial: Yup.number().required('Field Required'),
        uValueWinterMetric: Yup.number().required('Field Required'),
        uValueSummerImperial: Yup.number().required('Field Required'),
        uValueSummerMetric: Yup.number().required('Field Required'),
        sc: Yup.number().required('Field Required'),
        shgc: Yup.number().required('Field Required'),
        rhgImperial: Yup.number().required('Field Required'),
        rhgMetric: Yup.number().required('Field Required'),
        shortDescription: Yup.string().required('Field Required'),
        lsg: Yup.number().required('Field Required'),
    });

    const onSubmitHandler = async (formData) => {
        updateFunction(formData);
        onCloseButtonClickHandler();
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <Formik
                            enableReinitialize={formikUpdate}
                            initialValues={formikInitialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitHandler}
                        >
                            {({ errors }) => (
                                <Form className="flex flex-col w-full gap-2 py-4">
                                    <div className="flex flex-row w-full">
                                        <div className="flex flex-col w-1/2 px-2">
                                            <FormTextInput name="product" label='Product Description' />
                                            <FormTextInput name="standartThicknessImperial" label='Standart Thickness IP' />
                                            <FormTextInput name="averageThicknessImperial" label='Average Thickness IP' />
                                            <FormTextInput name="tVisible" label='Transmission Visible' />
                                            <FormTextInput name="tUv" label='Transmission Uv' />
                                            <FormTextInput name="visIn" label='Reflectance Vis-In' />
                                            <FormTextInput name="uValueWinterImperial" label='U-Value Winter IP' />
                                            <FormTextInput name="uValueSummerImperial" label='U-Value Summer IP' />
                                            <FormTextInput name="sc" label='Shading Coefficient' />
                                            <FormTextInput name="rhgImperial" label='Relative Heat Gain IP' />
                                            <FormTextInput name="shortDescription" label='Short Description' />
                                        </div>
                                        <div className="flex flex-col w-1/2 px-2">
                                            <FormTextInput name="productFamilyId" label='Product Family ID' />
                                            <FormTextInput name="standartThicknessMetric" label='Standart Thickness' />
                                            <FormTextInput name="averageThicknessMetric" label='Average Thickness' />
                                            <FormTextInput name="tSolar" label='Transmission Solar' />
                                            <FormTextInput name="visOut" label='Reflectance Vis-Out' />
                                            <FormTextInput name="visSolar" label='Reflectance solar' />
                                            <FormTextInput name="uValueWinterMetric" label='U-Value Winter' />
                                            <FormTextInput name="uValueSummerMetric" label='U-Value Summer' />
                                            <FormTextInput name="shgc" label='Solar Heat Gain Coefficient' />
                                            <FormTextInput name="rhgMetric" label='Relative Heat Gain' />
                                            <FormTextInput name="lsg" label='Light to Solar Gain Ratio' />
                                        </div>
                                    </div>
                                    <button
                                        type="submit"
                                    >
                                        Create Approval
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

ProductEditModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    updateFunction: PropTypes.func.isRequired,
};

export default ProductEditModal;
