import { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import Modal from '../../components/Modal/Modal';
import FormTextInput from '../../components/Form/FormTextInput';
import { PRINT_NAME_CONSTANT } from '../../components/Dropdown/PrintConstant';

const SpandrelModal = ({
                         open, onClose, submitFunction, spandrelIndex
                     }) => {
    const [dropdownValue, setDropdownValue] = useState('Select Color');

    function handleColorDropdownChange(event) {
        setDropdownValue(event.target.value);
    }

    const onCloseButtonClickHandler = () => {
        onClose();
        setDropdownValue('Select Color');
    };

    const [formikInitialValues, setFormikInitialValues] = useState({
        customColor: '',
    });

    const validationSchema = Yup.object().shape({
        customColor: Yup.string(),
        color: Yup.string(),
    });

    const onSubmitHandler = async (formData) => {
        if (formData.customColor !== '') {
            try {
                submitFunction(spandrelIndex, formData.customColor);
                toast.success('Spandrel Created Successfully!');
                setDropdownValue('Select Color');
                onCloseButtonClickHandler();
            } catch {
                toast.error('Something went wrong. Please try again');
            }
        } else if (dropdownValue !== 'Select Color') {
            try {
                submitFunction(spandrelIndex, dropdownValue);
                toast.success('Spandrel Created Successfully!');
                setDropdownValue('Select Color');
                onCloseButtonClickHandler();
            } catch {
                toast.error('Something went wrong. Please try again');
            }
        } else {
            toast.error('Please use custom color or choice from the color dropdown!');
        }
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <Formik
                            enableReinitialize
                            initialValues={formikInitialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitHandler}
                        >
                            {({ errors }) => (
                                <Form className="flex flex-col gap-2 py-4">
                                    <FormTextInput name="customColor" label='Custom Color' />
                                    <select
                                        value={dropdownValue}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        onChange={handleColorDropdownChange}
                                    >
                                        <option value="0">Select Color</option>
                                        {PRINT_NAME_CONSTANT.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                    <button
                                        // isBlock
                                        // disabled={Object.keys(errors).length > 0 || isLoading}
                                        type="submit"
                                        // isLoading={isLoading}
                                    >
                                        Continue
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

SpandrelModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    submitFunction: PropTypes.func.isRequired,
    spandrelIndex: PropTypes.number.isRequired,
};

export default SpandrelModal;
