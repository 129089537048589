export const GLASS_CONSTANT = [
    {
        id: 0,
        name: 'Clear',
        thickness: [
            '1/8 - 3mm',
            '5/32 - 4mm',
            '3/16 - 5mm',
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
            '1/2 - 12mm'
        ]
    },
    {
        id: 1,
        name: 'Gray',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
        ]
    },
    {
        id: 2,
        name: 'Bronze',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
        ]
    },
    {
        id: 3,
        name: 'Blue-Green',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 4,
        name: 'Azuria',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 5,
        name: 'Starphire',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
        ]
    },
    {
        id: 6,
        name: 'Optiblue',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
        ]
    },
    {
        id: 7,
        name: 'CrystalGray',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
        ]
    },
    {
        id: 8,
        name: 'Optiwhite',
        thickness: [
            '1/8 - 3mm',
            '3/16 - 5mm',
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
            '1/2 - 12mm'
        ]
    },
    {
        id: 9,
        name: 'Solarblue',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 10,
        name: 'Pacifica',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 11,
        name: 'Optigray',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 12,
        name: 'UltraClear',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
        ]
    },
    {
        id: 13,
        name: 'Pure Mid Iron',
        thickness: [
            '1/4 - 6mm',
            '5/16 - 8mm',
            '3/8 - 10mm',
        ]
    },
    {
        id: 14,
        name: 'Clear RoomSide Low-E',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 15,
        name: 'Clear Datastop',
        thickness: [
            '3/16 - 5mm',
            '1/4 - 6mm',
        ]
    },
    {
        id: 16,
        name: 'UltraWhite',
        thickness: [
            '1/4 - 6mm',
        ]
    },
    {
        id: 17,
        name: 'Green',
        thickness: [
            '1/4 - 6mm',
        ]
    },
]

export const GLASS_NAME_CONSTANT = [
    'Azuria',
    'Blue-Green',
    'Bronze',
    'Clear',
    'Clear Datastop',
    'Clear RoomSide Low-E',
    'CrystalGray',
    'Gray',
    'Green',
    'Optiblue',
    'Optigray',
    'Optiwhite',
    'Pacifica',
    'Pure Mid Iron',
    'Solarblue',
    'Starphire',
    'UltraClear',
    'UltraWhite',
]