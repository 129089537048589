import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            const user = JSON.parse(atob(payload.split('.')[1]));
            return {
                ...user,
                firstName: user.firstname,
                lastName: user.lastname,
                accessToken: payload,
            };
        },
        clearUserToken: (state) => {
            state.accessToken = null;
        },
    },
});

export const { setUser, clearUserToken } = userSlice.actions;

export default userSlice.reducer;

// Selectors

export const getUser = ({ user }) => user;

export const isUserAuthenticated = ({ user }) => user && user.accessToken;
