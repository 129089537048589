export const INTERLAYER_CONSTANT = [
    {
        id: 0,
        name: 'Absolute Black PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 1,
        name: 'Acoustical PVB',
        thickness: [
            '.030',
        ]
    },
    {
        id: 2,
        name: 'Arctic Snow PVB Vanceva',
        thickness: [
            '.015',
            '.030',
        ]
    },
    {
        id: 3,
        name: 'Black 50% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 4,
        name: 'Black 78% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 5,
        name: 'Blue 50% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 6,
        name: 'Blue 78% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 7,
        name: 'Clear PVB',
        thickness: [
            '.015',
            '.030',
            '.045',
            '.060',
        ]
    },
    {
        id: 8,
        name: 'Cool White PVB Vanceva',
        thickness: [
            '.015',
            '.030',
        ]
    },
    {
        id: 9,
        name: 'Deep Red PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 10,
        name: 'Ocean Grey PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 11,
        name: 'Polar White 7% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 12,
        name: 'Red 50% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 13,
        name: 'Red 78% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 14,
        name: 'Saflex Storm',
        thickness: [
            '.077',
        ]
    },
    {
        id: 15,
        name: 'Saflex Storm-AS',
        thickness: [
            '.092',
        ]
    },
    {
        id: 16,
        name: 'Saflex Storm-CW',
        thickness: [
            '.092',
        ]
    },
    {
        id: 17,
        name: 'Saflex Storm-PW',
        thickness: [
            '.092',
        ]
    },
    {
        id: 18,
        name: 'SentryGlas',
        thickness: [
            '.060',
            '.090',
            '.100',
        ]
    },
    {
        id: 19,
        name: 'SentryGlas XTRA',
        thickness: [
            '.030',
            '.050',
            '.100',
        ]
    },
    {
        id: 20,
        name: 'StormGuard',
        thickness: [
            '.050',
        ]
    },
    {
        id: 21,
        name: 'Tangerine PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 22,
        name: 'True Blue PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 23,
        name: 'Yellow 78% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
    {
        id: 24,
        name: 'Yellow 86% PVB Vanceva',
        thickness: [
            '.015',
        ]
    },
]

export const INTERLAYER_NAME_CONSTANT = [
    'Absolute Black PVB Vanceva',
    'Acoustical PVB',
    'Arctic Snow PVB Vanceva',
    'Black 50% PVB Vanceva',
    'Black 78% PVB Vanceva',
    'Blue 50% PVB Vanceva',
    'Blue 78% PVB Vanceva',
    'Clear PVB',
    'Cool White PVB Vanceva',
    'Deep Red PVB Vanceva',
    'Ocean Grey PVB Vanceva',
    'Polar White 7% PVB Vanceva',
    'Red 50% PVB Vanceva',
    'Red 78% PVB Vanceva',
    'Saflex Storm',
    'Saflex Storm-AS',
    'Saflex Storm-CW',
    'Saflex Storm-PW',
    'SentryGlas',
    'SentryGlas XTRA',
    'StormGuard',
    'Tangerine PVB Vanceva',
    'True Blue PVB Vanceva',
    'Yellow 78% PVB Vanceva',
    'Yellow 86% PVB Vanceva',
]

