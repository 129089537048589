import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import IconSearch from '../../components/Icon/IconSearch';
import { useGetProductMutation, usePostProductDeleteMutation, usePostProductEditMutation } from '../../store/services/api';
import IconEdit from '../../components/Icon/IconEdit';
import IconTrash from '../../components/Icon/IconTrash';
import { getUser } from '../../store/slices/user';
import ProductEditModal from '../../components/ProductEditModal/ProductEditModal';

const ConfigurationDatabase = () => {
    const [searchId, setSearchId] = useState('');
    const [showNoProductView, setShowNoProductView] = useState(true);
    const [trigger, result] = useGetProductMutation();
    const [triggerDelete, resultDelete] = usePostProductDeleteMutation();
    const [triggerEdit] = usePostProductEditMutation();
    const user = useSelector(getUser);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editData, setEditData] = useState([]);

    const editModalOpenHandler = () => {
        setEditData(result.data);
        setIsEditModalOpen(true);
    }

    const modalUpdateFunction = async (updateData) => {
        const editResult = await triggerEdit(updateData);
        editResult.data ? toast.success('Edit Successful!') : toast.error('Edit Failed!');
        trigger(searchId);
    }

    const handleDelete = async (id) => {
        triggerDelete(id);
    }

    function handleTextFieldChange(event) {
        setSearchId(event.target.value);
    }

    const searchProductId = async () => {
        if(searchId === '') {
            toast.error('Search field cannot be empty!')
        } else {
            trigger(searchId);
        }
        // result.data ? toast.success('Approved Successfully!') : toast.error('Approved Failed!');
    }

    useEffect(() => {
        if (result.data) {
            setShowNoProductView(false);
        }
    }, [result.isSuccess]);


    useEffect(() => {
        if (resultDelete.data) {
            toast.success('Configuration Removed Successfully!')
            setShowNoProductView(true);
        }
    }, [resultDelete.isSuccess]);

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            <div className="flex flex-col h-full w-full">
                <div className="flex flex-row basis-2/6 items-center justify-center">
                    <h2 className="font-bold" >Search Product: </h2>
                    <div className="w-2/12 h-1/6 pl-4">
                        <input
                            type="string"
                            className="w-full h-full text-center rounded font-bold border-solid border-2 border-black"
                            placeholder="Product ID"
                            onChange={handleTextFieldChange}
                            value={searchId}
                        />
                    </div>
                    <div className="pl-4 w-1/12 h-1/6">
                        <button
                            type="submit"
                            onClick={() => searchProductId()}
                            className="bg-[#047bc2] text-white w-full h-full rounded"
                        >
                            <div className="flex flex-row w-full items-center justify-center">
                                <IconSearch />
                                <h2 className="pl-2">Search</h2>
                            </div>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col basis-4/6 items-center justify-start">
                    {showNoProductView &&
                    <h2 className="font-bold">No Product Searched</h2>
                    }

                    {result.isLoading &&
                    <h2 className="font-bold">Loading..........</h2>
                    }

                    {result.data === null &&
                    <h2 className="font-bold">There is not such record for {searchId}</h2>
                    }

                    {result.isSuccess && result.data !== null && !showNoProductView &&
                        <div className="flex flex-col h-full w-full">
                            <div className="flex flex-row w-full text-white text-sm pt-12">
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Family</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for Family
                        </span>
                                </div>
                                <div className="w-[20%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">Product</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for Product
                        </span>
                                </div>
                                <div className="flex flex-col w-[15%]">
                                    <div className="h-10 flex flex-row justify-center items-center bg-[#0d3857] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>Transmission</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Transmission
                            </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/3 text-center bg-[#3d6078] border-solid border-r-2 border-white flex flex-row justify-center items-center">Visible</h2>
                                        <h2 className="w-1/3 text-center bg-[#557489] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                        <h2 className="w-1/3 text-center bg-[#6e889a] border-solid border-r-2 border-white flex flex-row justify-center items-center">UV</h2>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[15%]">
                                    <div className=" h-10 flex flex-row justify-center items-center bg-[#3b6178] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>Reflectance</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for Reflectance
                            </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/3 text-center bg-[#628193] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-Out</h2>
                                        <h2 className="w-1/3 text-center bg-[#7690a0] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-In</h2>
                                        <h2 className="w-1/3 text-center bg-[#89a0ae] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                    </div>
                                </div>
                                <div className="flex flex-col w-[10%]">
                                    <div className="h-10 flex flex-row justify-center items-center bg-[#1f6e9e] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                        <h2>U-value</h2>
                                        <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                              Tooltip for U-value
                            </span>
                                    </div>
                                    <div className="flex flex-row h-10">
                                        <h2 className="w-1/2 text-center bg-[#4b8bb1] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                        <h2 className="w-1/2 text-center bg-[#6299bb] border-solid border-r-2 border-white flex flex-row justify-center items-center">Summer</h2>
                                    </div>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#0079c1] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">SC</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for SC
                        </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#128acb] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">SHGC</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for SHGC
                        </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">RHG</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for RHG
                        </span>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                    <h2 className="text-center">LSG</h2>
                                    <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for LSG
                        </span>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#3795ce]">
                                    <h2 className="text-center">Actions</h2>
                                </div>
                            </div>
                            <div className="flex flex-row w-full text-xs">
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">N/A</h2>
                                </div>
                                <div className="w-[20%] h-20 flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{result.data.description}</h2>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(result.data.Tvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(result.data.Tsol.toFixed(2))}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center">{(result.data.Tuv.toFixed(2))}</h2>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(result.data.Routvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(result.data.Rinvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center">{(result.data.Routsol).toFixed(2)}</h2>
                                </div>
                                <div className="w-[10%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/2 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(result.data.UvalCenterWinterIP).toFixed(2)}</h2>
                                    <h2 className="w-1/2 h-full flex justify-center items-center text-center">{(result.data.UvalCenterSummerIP).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(result.data.SCSummer).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(result.data.SHGC).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(result.data.RelHeatGainIP).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(result.data.LSG).toFixed(2)}</h2>
                                </div>
                                <div className="w-[15%] h-20 flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    {user.role ==='admin' &&
                                        <div className="flex flex-row w-full justify-evenly">
                                            <button type="submit" onClick={() => editModalOpenHandler()} aria-label="Edit" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconEdit /></button>
                                            <button type="submit" onClick={() => handleDelete(result.data.pd_product_id)} aria-label="Delete" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconTrash /></button>
                                        </div>
                                    }
                                </div>
                                {/* Designer Function Part End */}
                            </div>
                        </div>
                    }
                </div>
                <ProductEditModal
                    open={isEditModalOpen}
                    onClose={() => setIsEditModalOpen(false)}
                    data={editData}
                    updateFunction={modalUpdateFunction}
                />
            </div>
        </section>
    )
}

export default ConfigurationDatabase;