export const AIR_CONSTANT = [
    {
        id: 0,
        name: 'Air',
        thickness: [
            '5/16 - 7.5mm',
            '3/8 - 9mm',
            '7/16 - 11mm',
            '15/32 - 12mm',
            '1/2 - 13.2mm',
            '9/16 - 14mm',
            '5/8 - 15.5mm',
            '23/32 - 18mm',
            '3/4 - 18.5mm',
            '7/8 - 22mm',
            '1 - 25.4mm',
        ]
    },
    {
        id: 1,
        name: 'Argon',
        thickness: [
            '5/16 - 7.5mm',
            '3/8 - 9mm',
            '7/16 - 11mm',
            '15/32 - 12mm',
            '1/2 - 13.2mm',
            '9/16 - 14mm',
            '5/8 - 15.5mm',
            '23/32 - 18mm',
            '3/4 - 18.5mm',
            '7/8 - 22mm',
            '1 - 25.4mm',
        ]
    },
]

export const AIR_NAME_CONSTANT = [
    'Air',
    'Argon'
]