import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import Modal from '../../components/Modal/Modal';
import FormTextInput from '../../components/Form/FormTextInput';

const EditFamilyModal = ({
                         open, onClose, editFunction, editIndex, defaultValue
                     }) => {
    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const [formikInitialValues, setFormikInitialValues] = useState({
        familyName: defaultValue
    });

    const validationSchema = Yup.object().shape({
        familyName: Yup.string(),
    });

    useEffect(() => {
        setFormikInitialValues({
            familyName: defaultValue,
        });
    }, [defaultValue]);

    const onSubmitHandler = async (formData) => {
        try {
            editFunction(editIndex, formData.familyName);
            onCloseButtonClickHandler();
            toast.success('Success');
        } catch {
            toast.error('Something went wrong. Please try again');
        }
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <Formik
                            enableReinitialize
                            initialValues={formikInitialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmitHandler}
                        >
                            {({ errors }) => (
                                <Form className="flex flex-col gap-2 py-4">
                                    <FormTextInput name="familyName" label='Family Name' />
                                    <button
                                        // isBlock
                                        // disabled={Object.keys(errors).length > 0 || isLoading}
                                        type="submit"
                                        // isLoading={isLoading}
                                    >
                                        Continue
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

EditFamilyModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    editFunction: PropTypes.func.isRequired,
    defaultValue: PropTypes.string.isRequired,
    editIndex: PropTypes.number.isRequired,
};

export default EditFamilyModal;
