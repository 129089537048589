import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import LoginInput from './LoginInput';
import { useLoginMutation } from '../../store/services/login';
import { isUserAuthenticated, setUser } from '../../store/slices/user';
import { DASHBOARD_PATH } from '../../routes';

const validationSchema = Yup.object().shape({
    email: Yup.string().email('email error').required('field required'),
    password: Yup.string().min(2, 'password error').max(50, 'Password Error 2').required('Field required'),
});

const Login = () => {
    const [login, { isLoading }] = useLoginMutation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isUserLoggedIn = useSelector(isUserAuthenticated);

    const {
        handleSubmit, handleChange, handleBlur, values, errors, touched,
    } = useFormik({
        initialValues: {
            email: '',
            password: '',
            rememberme: false,
        },
        validationSchema,
        onSubmit: async (credentials) => {
            try {
                const from = location.state?.from?.pathname || '/';
                const { error } = await login(credentials);
                dispatch(setUser(error.data));
                toast.success('Login Successful');
                navigate(from, { replace: true });
            } catch (error) {
                toast.error('Login Failed');
            }
        },
    });
    
    return isUserLoggedIn ? (
        <Navigate
            to={DASHBOARD_PATH}
            state={{ from: location }}
            replace
        />
    ) : (
        <div className="min-h-full flex object-cover bg-login-image bg-cover bg-no-repeat">
            <div className="flex flex-col h-screen w-screen items-center justify-center">
                <div className="mx-auto w-full h-2/6 max-w-sm lg:w-96 m-16" />
                <div className="mx-auto w-full h-2/6 max-w-sm lg:w-96 bg-[#006ec7] bg-opacity-40 rounded m-16 text-white">
                    <div className="p-4">
                        <div>
                            <h2 className="text-xl font-bold text-slate-600 mt-1 text-white">
                                Login
                            </h2>
                        </div>
                        <div className="mt-4">
                            <form onSubmit={handleSubmit} method="POST" className="space-y-1">
                                <div>
                                    <LoginInput
                                        id="email"
                                        type="email"
                                        name="email"
                                        label="Email"
                                        value={values.email}
                                        error={errors.email}
                                        displayError={!!errors.email && !!touched.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>

                                <div>
                                    <LoginInput
                                        id="password"
                                        type="password"
                                        name="password"
                                        label="Password"
                                        value={values.password}
                                        error={errors.password}
                                        displayError={!!errors.password && !!touched.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>

                                <div className="flex items-center justify-between">
                                    <div>
                                        <label
                                            htmlFor="remember-me"
                                            className="ml-2 text-sm text-base-primary flex items-center"
                                        >
                                            <input
                                                id="remember-me"
                                                name="rememberme"
                                                type="checkbox"
                                                className="h-4 w-4 text-highlight-primary focus:ring-sky-500 border-gray-300 rounded mr-2"
                                                value={values.rememberme}
                                                onChange={handleChange}
                                            />
                                            Remember me
                                        </label>
                                    </div>
                                    <div className="pr-2">
                                        <button
                                            type="submit"
                                            className="bg-[#006ec7] pt-2 pb-2 pl-8 pr-8 font-bold border-solid border-2 border-white rounded"
                                            disabled={Object.keys(errors).length > 0 || isLoading}
                                            // isBlock
                                            // isLoading={isLoading}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="h-2/6 lg:w-56 p-2 m-16 object-cover bg-login-logo bg-no-repeat bg-contain" />
            </div>
        </div>
    );
}

export default Login;
