import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { Tab } from '@headlessui/react';
import { PDFDownloadLink, Document } from '@react-pdf/renderer';
import Modal from '../../components/Modal/Modal';
import IconPdf from '../../components/Icon/IconPdf';

const PdfDownloadModal = ({
                         open, onClose, pdfPageLayout
                     }) => {
    const currentDate = new Date().toDateString();
    const fileName = `${currentDate}.pdf`;

    const onCloseButtonClickHandler = () => {
        onClose();
    };

    const onSubmitHandler = async () => {
        onCloseButtonClickHandler();
        try {
            toast.success('Success');
        } catch {
            toast.error('Something went wrong. Please try again');
        }
    };

    return (
        <Modal open={open} setOpen={onCloseButtonClickHandler}>
            <div className="px-2">
                <Tab.Group>
                    <Tab.Panels>
                        <Formik
                            onSubmit={onSubmitHandler}
                        >
                            {({ errors }) => (
                                <Form className="flex flex-col gap-2 py-4">
                                    <PDFDownloadLink document={
                                        <Document>
                                            {pdfPageLayout.map((pdf) => (
                                                pdf
                                            ))}
                                        </Document>
                                    } fileName={fileName}>
                                        {({ blob, url, loading, error }) =>
                                            loading ?
                                                <div className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                                    <h2>Loading PDF document...</h2>
                                                </div> :
                                                <div className="bg-[#047bc2] text-white pl-4 pr-4 pt-2 pb-2 rounded">
                                                    <div className="flex flex-row">
                                                        <IconPdf />
                                                        <h2 className="pl-2">Download PDF Export</h2>
                                                    </div>
                                                </div>
                                        }
                                    </PDFDownloadLink>
                                </Form>
                            )}
                        </Formik>
                    </Tab.Panels>
                </Tab.Group>
            </div>
        </Modal>
    );
};

PdfDownloadModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    pdfPageLayout: PropTypes.array.isRequired,
};

export default PdfDownloadModal;
