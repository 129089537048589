// Pyrolytic Low-E-> not exist
// VC-24-> not exist

export const COATING_NAME_CONSTANT = [
    'No Coating',
    'All Coating',
    'Pyrolytic Low-E',
    'VC-24',
    'VA-08',
    'VA-13',
    'VA-18',
    'VA-22',
    'VA-35',
    'VB-08',
    'VB-14',
    'VB-20',
    'VB-24',
    'VE-2M',
    'VE-40',
    'VE-42',
    'VE-45',
    'VE-48',
    'VE-52',
    'VE-55',
    'VE-85',
    'VG-08',
    'VG-14',
    'VG-20',
    'VLE-39',
    'VLE-47',
    'VLE-51',
    'VLE-57',
    'VLE-70',
    'VLO-66',
    'VNE-37',
    'VNE-53',
    'VNE-63',
    'VNG-4022',
    'VP-13',
    'VP-18',
    'VP-22',
    'VP-30',
    'VP-40',
    'VP-60',
    'VRE-30',
    'VRE-38',
    'VRE-43',
    'VRE-3117',
    'VRE-4322',
    'VRE-46',
    'VRE-4725',
    'VRE-54',
    'VRE-59',
    'VRE-65',
    'VRE-67',
    'VS-08',
    'VS-14',
    'VS-20',
    'VS-30',
    'VS-40',
    'VSE-20',
    'VT-10',
    'VT-20',
    'VT-30',
    'VT-35',
    'VT-40',
    'VUE-30',
    'VUE-40',
    'VUE-50',
    'VXG-08',
    'VXG-14',
    'VXG-20',
    'VY-08',
    'VY-14',
    'VY-20',
    'VY-30',
    'VY-40',
    'VZE-SC',
]