import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { DASHBOARD_PATH, LOGIN_PATH } from '../routes';
import { getUser, isUserAuthenticated } from '../store/slices/user';

const ProtectedPage = ({ children, allowedRoles }) => {
    const isUserLoggedIn = useSelector(isUserAuthenticated);
    const user = useSelector(getUser);
    const userHasRights = allowedRoles.includes(user.role);
    const location = useLocation();

    if (isUserLoggedIn) {
        if (userHasRights) {
            return children;
        }

        return (
            <Navigate
                to={DASHBOARD_PATH}
                state={{ from: location }}
                replace
            />
        );
    }

    return (
        <Navigate
            to={LOGIN_PATH}
            replace
        />
    );
};

ProtectedPage.propTypes = {
    children: PropTypes.node.isRequired,
    allowedRoles: PropTypes.array.isRequired,
};

export default ProtectedPage;
