export const PRINT_CONSTANT = [
    {
        id: 1,
        name: 'VPC2009 BRONZE',
        code: 'VPC2009'
    },
    {
        id: 2,
        name: 'VPC2110 FOG GRAY',
        code: 'VPC2110'
    },
    {
        id: 3,
        name: 'VBC2029 BROWN',
        code: 'VBC2029'
    },
    {
        id: 4,
        name: 'VBC2033 CLOUD GRAY',
        code: 'VBC2033'
    },
    {
        id: 5,
        name: 'VBC2034 PEWTER GRAY',
        code: 'VBC2034'
    },
    {
        id: 6,
        name: 'VBC2038 MEDIUM BLUE',
        code: 'VBC2038'
    },
    {
        id: 7,
        name: 'VBC2039 TIMBER GRAY',
        code: 'VBC2039'
    },
    {
        id: 8,
        name: 'VBC2042 TAN',
        code: 'VBC2042'
    },
    {
        id: 9,
        name: 'VBC2055 GOLD BEIGE',
        code: 'VBC2055'
    },
    {
        id: 10,
        name: 'VBC2066 MUSTARD YLW',
        code: 'VBC2066'
    },
    {
        id: 11,
        name: 'VBC2109 DARK GRAY',
        code: 'VBC2109'
    },
    {
        id: 12,
        name: 'VBC2067 MIDNIT BLU',
        code: 'VBC2067'
    },
    {
        id: 13,
        name: 'VBC2073 PEBBLE GRY',
        code: 'VBC2073'
    },
    {
        id: 14,
        name: 'VBC2104 DARK GOLD',
        code: 'VBC2104'
    },
    {
        id: 15,
        name: 'VBC2105 LITE GOLD',
        code: 'VBC2105'
    },
    {
        id: 16,
        name: 'VBC2111 SUBDUE BRZ',
        code: 'VBC2111'
    },
    {
        id: 17,
        name: 'V1092 SIM ACID ETCH',
        code: 'V1092'
    },
    {
        id: 18,
        name: 'V1093 SIM SANDBLAST',
        code: 'V1093'
    },
    {
        id: 19,
        name: 'V1094 FOG GRAY',
        code: 'V1094'
    },
    {
        id: 20,
        name: 'V951 WHITE',
        code: 'V951'
    },
    {
        id: 21,
        name: 'V952 WARM GRAY',
        code: 'V952'
    },
    {
        id: 22,
        name: 'V953 MED GRAY',
        code: 'V953'
    },
    {
        id: 23,
        name: 'V954 GRAY',
        code: 'V954'
    },
    {
        id: 24,
        name: 'V955 BLACK',
        code: 'V955'
    },
    {
        id: 25,
        name: 'V956 SUBDUED BRONZE',
        code: 'V956'
    },
    {
        id: 26,
        name: 'V957 SUBDUED GRAY',
        code: 'V957'
    },
    {
        id: 27,
        name: 'V958 DARK GRAY',
        code: 'V958'
    },
    {
        id: 28,
        name: 'VPC2169 SL PARK GRY',
        code: 'VPC2169'
    },
    {
        id: 29,
        name: 'VPC2170 ICED SLATE',
        code: 'VPC2170'
    },
    {
        id: 30,
        name: 'VPC2004 MILK WHITE',
        code: 'VPC2004'
    },
    {
        id: 31,
        name: 'VPC2171 DK GULL GRY',
        code: 'VPC2171'
    },
    {
        id: 32,
        name: 'VPC2172 CMU ERL GRY',
        code: 'VPC2172'
    },
    {
        id: 33,
        name: 'VPC2173 MARINO WHT',
        code: 'VPC2173'
    },
    {
        id: 34,
        name: 'VPC2174 SWEDISH GRY',
        code: 'VPC2174'
    },
    {
        id: 35,
        name: 'VPC2175 TAYLOR &6TH',
        code: 'VPC2175'
    },
    {
        id: 36,
        name: 'VD105 GRAY',
        code: 'VD105'
    },
    {
        id: 37,
        name: 'VPC2176 PSU BLUE',
        code: 'VPC2176'
    },
    {
        id: 38,
        name: 'VPC2108 WOLF GRAY',
        code: 'VPC2108'
    },
    {
        id: 39,
        name: 'VPC2100 NORWALK GRY',
        code: 'VPC2100'
    },
    {
        id: 40,
        name: 'VPC2177 T9 WHITE',
        code: 'VPC2177'
    },
    {
        id: 41,
        name: 'VPC2155 ASPEN SKIES',
        code: 'VPC2155'
    },
    {
        id: 42,
        name: 'VPC2003 BLK OF NGH',
        code: 'VPC2003'
    },
    {
        id: 43,
        name: 'VD103 WARM GRAY',
        code: 'VD103'
    },
    {
        id: 44,
        name: 'VPC2160 LHS GRY BLA',
        code: 'VPC2160'
    },
    {
        id: 45,
        name: 'VPC2122 MT AUBU GRN',
        code: 'VPC2122'
    },
    {
        id: 46,
        name: 'VPC2168 SPARTAN GRN',
        code: 'VPC2168'
    },
    {
        id: 47,
        name: 'VD101 WHITE',
        code: 'VD101'
    },
    {
        id: 48,
        name: 'VD102 BLACK',
        code: 'VD102'
    },
    {
        id: 49,
        name: 'VPC2178 ATLANTI GRY',
        code: 'VPC2178'
    },
    {
        id: 50,
        name: 'VPC2088 RBR BLI BLU',
        code: 'VPC2088'
    },
    {
        id: 51,
        name: 'VPC2089 RBR CLDBURS',
        code: 'VPC2089'
    },
    {
        id: 52,
        name: 'VPC2090 RBR MEA BLU',
        code: 'VPC2090'
    },
    {
        id: 53,
        name: 'VPC2099 RBR GRIZGRY',
        code: 'VPC2099'
    },
    {
        id: 54,
        name: 'VPC2179 INT\'L WHITE',
        code: 'VPC2179'
    },
    {
        id: 55,
        name: 'VPC2152 RAD LT R EA',
        code: 'VPC2152'
    },
    {
        id: 56,
        name: 'VPC2153 RCH LT REDE',
        code: 'VPC2153'
    },
    {
        id: 57,
        name: 'VPC2159 RAD CA CLAY',
        code: 'VPC2159'
    },
    {
        id: 58,
        name: 'V950 VELOC WARM GR',
        code: 'V950'
    },
    {
        id: 59,
        name: 'VPC2162 QUIET ALCOV',
        code: 'VPC2162'
    },
    {
        id: 60,
        name: 'VPC2180 SCIEN 2 GRN',
        code: 'VPC2180'
    },
    {
        id: 61,
        name: 'VPC2181 TCV CHARCOA',
        code: 'VPC2181'
    },
    {
        id: 62,
        name: 'VPC2016 UMD GRAY',
        code: 'VPC2016'
    },
    {
        id: 63,
        name: 'VPC2182 BLUE CLOUD',
        code: 'VPC2182'
    },
    {
        id: 64,
        name: 'VPC2101 MERCER GRAY',
        code: 'VPC2101'
    },
    {
        id: 65,
        name: 'VPC2183 MIDSUMMER N',
        code: 'VPC2183'
    },
    {
        id: 66,
        name: 'VDP200 WHITE',
        code: 'VDP200'
    },
    {
        id: 67,
        name: 'VDP300 BLACK',
        code: 'VDP300'
    },
    {
        id: 68,
        name: 'VBC2002 SLATE BLUE',
        code: 'VBC2002'
    },
    {
        id: 69,
        name: 'VBC2006 BRN BEIGE',
        code: 'VBC2006'
    },
    {
        id: 70,
        name: 'VBC2007 DARK GREEN',
        code: 'VBC2007'
    },
    {
        id: 71,
        name: 'VBC2014 ALABAS WHT',
        code: 'VBC2014'
    },
    {
        id: 72,
        name: 'VBC2015 IRON GRAY',
        code: 'VBC2015'
    },
    {
        id: 73,
        name: 'VBC2017 GUNMET GRY',
        code: 'VBC2017'
    },
    {
        id: 74,
        name: 'VBC2019 SMOKE GRAY',
        code: 'VBC2019'
    },
    {
        id: 75,
        name: 'VPC2148 GRANDVW GRN',
        code: 'VPC2148'
    },
    {
        id: 76,
        name: 'VPC2158 BRYN BK GRY',
        code: 'VPC2158'
    },
    {
        id: 77,
        name: 'VPC2025 CHI P CHAMP',
        code: 'VPC2025'
    },
    {
        id: 78,
        name: 'VPC2056 PSU COPPER',
        code: 'VPC2056'
    },
    {
        id: 79,
        name: 'VPC2165 H O SUB GRY',
        code: 'VPC2165'
    },
    {
        id: 80,
        name: 'VPC2022 SONY LT BLU',
        code: 'VPC2022'
    },
    {
        id: 81,
        name: 'VPC2123 MZG GRY MIC',
        code: 'VPC2123'
    },
    {
        id: 82,
        name: 'VPC2166 KAIS LT BRZ',
        code: 'VPC2166'
    },
    {
        id: 83,
        name: 'VPC2043 DIRECT BEIG',
        code: 'VPC2043'
    },
    {
        id: 84,
        name: 'VPC2078 MET SOO GRY',
        code: 'VPC2078'
    },
    {
        id: 85,
        name: 'VPC2149 520 NEW BRN',
        code: 'VPC2149'
    },
    {
        id: 86,
        name: 'VPC2008 DARK BRONZE',
        code: 'VPC2008'
    },
    {
        id: 87,
        name: 'VPC2167 MARION CITY',
        code: 'VPC2167'
    },
    {
        id: 88,
        name: 'VPC2024 RUFUS BLCK',
        code: 'VPC2024'
    },
    {
        id: 89,
        name: 'VPC2005 PANCHO BRN',
        code: 'VPC2005'
    },
    {
        id: 90,
        name: 'VBC2113 FROST WHT',
        code: 'VBC2113'
    },
    {
        id: 91,
        name: 'VBC2127 STONE GRAY',
        code: 'VBC2127'
    },
    {
        id: 92,
        name: 'VBC2128 LIGHT BLUE',
        code: 'VBC2128'
    },
    {
        id: 93,
        name: 'VBC2129 EMERAL GRN',
        code: 'VBC2129'
    },
    {
        id: 94,
        name: 'VPC2184 TAN',
        code: 'VPC2184'
    },
    {
        id: 95,
        name: 'VPC2031 THEATER GRN',
        code: 'VPC2031'
    },
    {
        id: 96,
        name: 'VPC2103 CONRA OFWHT',
        code: 'VPC2103'
    },
    {
        id: 97,
        name: 'VPC2185 SFE BK MAGI',
        code: 'VPC2185'
    },
    {
        id: 98,
        name: 'VPC2186 SFE BK FOX',
        code: 'VPC2186'
    },
    {
        id: 99,
        name: 'No Print',
        code: 'No Print'
    },
    {
        id: 100,
        name: 'All Print',
        code: 'All Print'
    },
];

export const PRINT_NAME_CONSTANT = [
    'No Print',
    'All Print',
    'V951 WHITE',
    'V952 WARM GRAY',
    'V953 MED GRAY',
    'V954 GRAY',
    'V955 BLACK',
    'V957 SUBDUED GRAY',
    'V1092 SIM ACID ETCH',
    'V1093 SIM SANDBLAST',
    'VBC2002 SLATE BLUE',
    'VBC2006 BRN BEIGE',
    'VBC2007 DARK GREEN',
    'VBC2014 ALABAS WHT',
    'VBC2015 IRON GRAY',
    'VBC2017 GUNMET GRY',
    'VBC2019 SMOKE GRAY',
    'VBC2029 BROWN',
    'VBC2033 CLOUD GRAY',
    'VBC2034 PEWTER GRAY',
    'VBC2038 MEDIUM BLUE',
    'VBC2039 TIMBER GRAY',
    'VBC2042 TAN',
    'VBC2055 GOLD BEIGE',
    'VBC2066 MUSTARD YLW',
    'VBC2067 MIDNIT BLU',
    'VBC2073 PEBBLE GRY',
    'VBC2104 DARK GOLD',
    'VBC2105 LITE GOLD',
    'VBC2109 DARK GRAY',
    'VBC2111 SUBDUE BRZ',
    'VBC2113 FROST WHT',
    'VBC2127 STONE GRAY',
    'VBC2128 LIGHT BLUE',
    'VBC2129 EMERAL GRN',
    'VPC2110 FOG GRAY',
];
