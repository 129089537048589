import { createApi } from '@reduxjs/toolkit/query/react';
import { baseUnauthenticatedQuery, serviceUrl } from './setting';

export const loginApi = createApi({
    reducerPath: 'loginApi',
    baseQuery: baseUnauthenticatedQuery,
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: serviceUrl.login,
                method: 'POST',
                body: credentials,
            }),
        }),

        requestPasswordReset: builder.mutation({
            query: (email) => ({
                url: serviceUrl.forgot,
                method: 'POST',
                body: { email },
            }),
        }),
    }),
});

export const {
    useLoginMutation,
    useRequestPasswordResetMutation,
} = loginApi;
