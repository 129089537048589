import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithErrorHandling, defaultQuery, serviceUrl } from './setting';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithErrorHandling,
    endpoints: (builder) => ({
        getReports: builder.mutation({
            query: (credentials) => ({
                url: serviceUrl.report,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        PostBulkSearchById: builder.mutation({
            query: (credentials) => ({
                url: `${serviceUrl.report}/bulkSearchById`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        getRuleCheck: builder.mutation({
            query: (credentials) => ({
                url: serviceUrl.ruleCheck,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postCreateApprovalRequest: builder.mutation({
            query: (payload) => ({
                url: `${serviceUrl.report}/createApprovalRequest`,
                method: 'POST',
                body: payload,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postCreateCalculationRequest: builder.mutation({
            query: (credentials) => ({
                url: `${serviceUrl.calculationApproval}/create`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        getApprovalList: builder.query({
            query: () => (
                defaultQuery(`${serviceUrl.approval}`)
            ),
        }),

        getCalculationApprovalList: builder.query({
            query: () => (
                defaultQuery(`${serviceUrl.calculationApproval}`)
            ),
        }),

        postApprovalConfirm: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.approval}/confirm/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postCalculateApproval: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.calculationApproval}/calculate/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postCalculationApprovalConfirm: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.calculationApproval}/confirm/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postApprovalDelete: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.approval}/delete/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postCalculateApprovalDelete: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.calculationApproval}/delete/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postApprovalEdit: builder.mutation({
            query: (credentials) => ({
                url: `${serviceUrl.approval}/edit`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postApprovalManuelEntry: builder.mutation({
            query: (credentials) => ({
                url: `${serviceUrl.approval}/createManuel`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        getProduct: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.product}/search/${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        getApprovedProduct: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.product}/searchApproved/${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postProductDelete: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.product}/delete/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postProductEdit: builder.mutation({
            query: (credentials) => ({
                url: `${serviceUrl.product}/edit`,
                method: 'POST',
                body: credentials,
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        getComponent: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.component}/search/${id}`,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

        postComponentAdd: builder.mutation({
            query: (file) => ({
                url: `${serviceUrl.component}/add`,
                method: 'POST',
                body: file,
            }),
        }),

        postComponentDelete: builder.mutation({
            query: (id) => ({
                url: `${serviceUrl.component}/delete/${id}`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
            }),
        }),

    }),
});

export const {
    useGetReportsMutation,
    usePostBulkSearchByIdMutation,
    useGetRuleCheckMutation,
    usePostCreateApprovalRequestMutation,
    usePostCreateCalculationRequestMutation,
    useGetApprovalListQuery,
    useGetCalculationApprovalListQuery,
    usePostApprovalConfirmMutation,
    usePostCalculateApprovalMutation,
    usePostCalculationApprovalConfirmMutation,
    usePostApprovalDeleteMutation,
    usePostCalculateApprovalDeleteMutation,
    usePostApprovalEditMutation,
    usePostApprovalManuelEntryMutation,
    useGetProductMutation,
    useGetApprovedProductMutation,
    usePostProductDeleteMutation,
    usePostProductEditMutation,
    useGetComponentMutation,
    usePostComponentAddMutation,
    usePostComponentDeleteMutation,
} = api;

export const { resetApiState } = api.util;
