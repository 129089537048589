import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query';
// eslint-disable-next-line import/no-cycle
import { clearUserToken } from '../slices/user';

export const serviceUrl = {
    baseUrl: process.env.REACT_APP_API_BASE_URL,
    login: 'login',
    logout: 'logout',
    report: 'report',
    approval: 'approval',
    calculationApproval: 'calculationApproval',
    product: 'product',
    component: 'component',
    ruleCheck: 'ruleCheck'
};

const baseQuery = fetchBaseQuery({
    baseUrl: serviceUrl.baseUrl,
    prepareHeaders: (headers, { getState }) => {
        const { accessToken } = getState().user;
        headers.set('authorization', accessToken);
        return headers;
    },
});

export const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    if (result.error) {
        if (result.error.status === 401) {
            api.dispatch(clearUserToken());
        } else if (result.error.status === 500) {
            // console.error("Internal Server Error:", result.error.data.message);
        }
    }

    return result;
};

export const baseUnauthenticatedQuery = fetchBaseQuery({
    baseUrl: serviceUrl.baseUrl,
    prepareHeaders: (headers) => {
        headers.set('Content-Type', 'application/json; charset=utf-8');
        return headers;
    },
});

export const defaultQuery = (urlSlug, queryObject) => {
    const queryParams = new URLSearchParams(queryObject).toString();
    return `${urlSlug}?${queryParams}`;
};
