import {Navigate, Route, Routes} from 'react-router-dom';
import { t } from 'i18next';
import { Helmet } from 'react-helmet';
import ToastWrapper from './components/ToastWrapper/ToastWrapper';
import MainLayout from './layouts/MainLayout';
import MainPage from './containers/MainPage/MainPage';
import {
    ALLOWED_ROLES,
    APPROVAL_DASHBOARD_PATH,
    COMPONENT_DATABASE_PATH, COMPONENT_REQUEST_PATH,
    CONFIGURATION_DATABASE_PATH,
    DASHBOARD_PATH,
    LOGIN_PATH,
    REPORT_PATH
} from './routes';
import SimpleLayout from './layouts/SimpleLayout';
import Login from './containers/Login/Login';
import ProtectedPage from './utils/ProtectedPage';
import Report from './containers/Report/Report';
import ApprovalDashboard from './containers/ApprovalDashboard/ApprovalDashboard';
import ConfigurationDatabase from './containers/ConfigurationDatabase/ConfigurationDatabase';
import ComponentDatabase from './containers/ComponentDatabase/ComponentDatabase';
import ComponentRequest from './containers/ComponentRequest/ComponentRequest';

const App = () => (
    <>
        <ToastWrapper/>

        <Helmet>
            <title>{t('appTitle')}</title>
        </Helmet>

        <Routes>

        <Route path="/" element={<MainLayout />}>

            <Route
                path="/"
                element={<Navigate to={DASHBOARD_PATH} />}
            />

            <Route
                path={DASHBOARD_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[DASHBOARD_PATH]}
                    >
                        <MainPage />
                    </ProtectedPage>
                )}
            />

            <Route
                path={REPORT_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[REPORT_PATH]}
                    >
                        <Report />
                    </ProtectedPage>
                )}
            />

            <Route
                path={APPROVAL_DASHBOARD_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[APPROVAL_DASHBOARD_PATH]}
                    >
                        <ApprovalDashboard />
                    </ProtectedPage>
                )}
            />

            <Route
                path={CONFIGURATION_DATABASE_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[CONFIGURATION_DATABASE_PATH]}
                    >
                        <ConfigurationDatabase />
                    </ProtectedPage>
                )}
            />

            <Route
                path={COMPONENT_DATABASE_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[COMPONENT_DATABASE_PATH]}
                    >
                        <ComponentDatabase />
                    </ProtectedPage>
                )}
            />

            <Route
                path={COMPONENT_REQUEST_PATH}
                element={(
                    <ProtectedPage
                        allowedRoles={ALLOWED_ROLES[COMPONENT_REQUEST_PATH]}
                    >
                        <ComponentRequest />
                    </ProtectedPage>
                )}
            />

        </Route>

        <Route path={LOGIN_PATH} element={<SimpleLayout />}>
                <Route
                    path={LOGIN_PATH}
                    element={<Login />}
                    exact
                />

            </Route>

        </Routes>
    </>
);

export default App;
