import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../store/slices/user';
import {
    useGetCalculationApprovalListQuery,
    usePostCalculationApprovalConfirmMutation,
    usePostCalculateApprovalDeleteMutation,
    usePostCalculateApprovalMutation
} from '../../store/services/api';
import IconTrash from '../../components/Icon/IconTrash';
import IconConfirm from '../../components/Icon/IconConfirm';
import IconCalculate from '../../components/Icon/IconCalculate';
import { REPORT_PATH } from '../../routes';
import IconSearch from '../../components/Icon/IconSearch';

const ComponentRequest = () => {
    const navigate = useNavigate();
    const { data, isFetching } = useGetCalculationApprovalListQuery();
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [calculateTrigger] = usePostCalculateApprovalMutation();
    const [approvalTrigger] = usePostCalculationApprovalConfirmMutation();
    const [deleteTrigger] = usePostCalculateApprovalDeleteMutation();
    const user = useSelector(getUser);

    const search = (requestData) => {
        navigate(
            `${REPORT_PATH}`,
            {
                state:
                    {
                        data: requestData,
                    }
            }
        );
    }

    const calculate = async (id) => {
        setIsLoading(true);
        const result = await calculateTrigger(id);
        setIsLoading(false);
        if(result.data.status === 'success') {
            toast.success('You will be redirected to product, please wait...');
            setTimeout(() => {  window.open(result.data.link, "_blank"); }, 2000);
        } else {
            toast.error(result.data.message);
        }
    }

    const handleConfirmApproval = async (index, id) => {
        setIsLoading(true);
        // const result = await getProduct({requestData});
        // console.log(result);

        console.log(id);

        const result = await approvalTrigger(id);

        if(result.data.status === 'success') {
            toast.success(result.data.message);
            const updatedTableData = [...tableData.slice(0, index), ...tableData.slice(index + 1)];
            setTableData(updatedTableData);
        } else {
            toast.error(result.data.message);
        }
        setIsLoading(false);
    }

    const handleDeleteApproval = async (index, id) => {
        setIsLoading(true);
       const result = await deleteTrigger(id);

        const updatedTableData = [...tableData.slice(0, index), ...tableData.slice(index + 1)];
        setTableData(updatedTableData);

        result.data ? toast.success('Delete Successfully!') : toast.error('Delete Failed!');
        setIsLoading(false);
    }

    useEffect(() => {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            {isFetching &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait until page loading...</p>
                </div>
            }

            {isLoading &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait...</p>
                </div>
            }

            {!isFetching && !isLoading &&
                <div className="flex flex-col h-full">
                    <div className="flex flex-row w-full text-white text-sm pt-12">
                        <div className="w-[10%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                            <h2 className="text-center">Requested By</h2>
                            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for Requested By
                        </span>
                        </div>
                        <div className="w-[75%] h-20 flex justify-center items-center bg-[#0079c1] border-solid border-r-2 border-white group relative cursor-pointer">
                            <h2 className="text-center">Description</h2>
                            <span className="opacity-0 group-hover:opacity-100 transition-opacity duration-200 absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-800 text-white text-xs py-1 px-2 rounded-md">
                          Tooltip for Description
                        </span>
                        </div>
                        <div className="w-[15%] h-20 flex justify-center items-center bg-[#3795ce]">
                            <h2 className="text-center">Actions</h2>
                        </div>
                    </div>
                    {tableData.map((item, index) => (
                        <div className="flex flex-row w-full h-auto text-xs">
                            <div className="w-[10%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                <h2 className="text-center">{item.created_by}</h2>
                            </div>
                            <div className="w-[75%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                <h2 className="text-center">{item.description}</h2>
                            </div>
                            <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                {user.role ==='admin' &&
                                    <div className="flex flex-row w-full justify-evenly">
                                        <button type="submit" onClick={() => search(item.request_object)} aria-label="Calculate" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center m-1"><IconSearch /></button>
                                        <button type="submit" onClick={() => calculate(item.id)} aria-label="Calculate" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center m-1"><IconCalculate /></button>
                                        <button type="submit" onClick={() => handleConfirmApproval(index, item.id)} aria-label="Confirm" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center m-1"><IconConfirm /></button>
                                        <button type="submit" onClick={() => handleDeleteApproval(index, item.id)} aria-label="Delete" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center m-1"><IconTrash /></button>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </div>
            }
        </section>
    )
}

export default ComponentRequest;