import PropTypes from 'prop-types';
import classNames from 'classnames';

import {
    Transition, Menu,
} from '@headlessui/react';
import { Fragment } from 'react';

const DropdownInterlayer = ({ children, menuButton, position }) => (
    <Menu as="div" className="relative inline-block text-left">
        <div>
            <Menu.Button className="rounded-full p-1 flex items-center text-gray-400">
                {menuButton}
            </Menu.Button>
        </div>

        <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
        >
            <Menu.Items
                className={classNames('origin-top-right absolute mt-8 ml-[-90px] w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50', {
                    'top-0': position === 'below',
                    'bottom-0': position === 'above',
                    'right-0': position === 'left',
                })}
            >
                <div className="py-2 border-solid border border-[#0079C1] rounded">
                    {children}
                </div>
            </Menu.Items>
        </Transition>
    </Menu>

);

DropdownInterlayer.propTypes = {
    children: PropTypes.node.isRequired,
    menuButton: PropTypes.node.isRequired,
    position: PropTypes.string,
};

DropdownInterlayer.defaultProps = {
    position: 'below',
};

export default DropdownInterlayer;
