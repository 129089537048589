import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import { getUser } from '../../store/slices/user';
import {
    useGetApprovalListQuery,
    usePostApprovalConfirmMutation,
    usePostApprovalDeleteMutation,
    usePostApprovalEditMutation,
    usePostApprovalManuelEntryMutation
} from '../../store/services/api';
import IconTrash from '../../components/Icon/IconTrash';
import IconEdit from '../../components/Icon/IconEdit';
import IconConfirm from '../../components/Icon/IconConfirm';
import ProductEditModal from '../../components/ProductEditModal/ProductEditModal';
import IconAddManuel from '../../components/Icon/IconAddManuel';
import ManuelEntryModal from '../../components/ManuelEntryModal/ManuelEntryModal';

const ApprovalDashboard = () => {
    const { data, isFetching } = useGetApprovalListQuery();
    const [tableData, setTableData] = useState([]);
    const [approvalTrigger] = usePostApprovalConfirmMutation();
    const [deleteTrigger] = usePostApprovalDeleteMutation();
    const [editTrigger] = usePostApprovalEditMutation();
    const [manuelEntryTrigger] = usePostApprovalManuelEntryMutation();
    const user = useSelector(getUser);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isManuelEntryModalOpen, setIsManuelEntryModalOpen] = useState(false);
    const [editData, setEditData] = useState([]);

    const manuelEntryModalOpenHandler = () => {
        setIsManuelEntryModalOpen(true);
    }

    const modalManuelEntryUpdateFunction = async (newApprovalData) => {
        const result = await manuelEntryTrigger(newApprovalData);
        result.data ? toast.success('Manual Entry Successful!') : toast.error('Manual Entry Failed!');
        window.location.reload(false);
    }

    const editModalOpenHandler = (index) => {
        setEditData(tableData[index]);
        setIsEditModalOpen(true);
    }

    const modalUpdateFunction = async (updateData) => {
        const result = await editTrigger(updateData);
        result.data ? toast.success('Edit Successful!') : toast.error('Edit Failed!');
        window.location.reload(false);
    }

    const handleConfirmApproval = async (index, id) => {
        const result = await approvalTrigger(id);

        const updatedTableData = [...tableData.slice(0, index), ...tableData.slice(index + 1)];
        setTableData(updatedTableData);

        result.data ? toast.success('Approved Successfully!') : toast.error('Approved Failed!');
    }

    const handleDeleteApproval = async (index, id) => {
        const result = await deleteTrigger(id);

        const updatedTableData = [...tableData.slice(0, index), ...tableData.slice(index + 1)];
        setTableData(updatedTableData);

        result.data ? toast.success('Delete Successfully!') : toast.error('Delete Failed!');
    }

    useEffect(() => {
        if (data) {
            setTableData(data);
        }
    }, [data]);

    return (
        <section className="h-[calc(100vh_-_2rem)] w-full bg-transparent p-4 rounded">
            {isFetching &&
                <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center">
                    <div className="inline-block animate-spin ease-linear rounded-full border-t-4 border-blue-500 border-solid h-12 w-12" />
                    <p className="mt-2 text-blue-500">Please wait until page loading...</p>
                </div>
            }

            {!isFetching &&
                <>
                    <div className="flex flex-row justify-end">
                        <button type="submit" onClick={() => manuelEntryModalOpenHandler()} aria-label="Confirm" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center">
                            <span className="pr-2">
                                <IconAddManuel />
                            </span>Add Manual Product Approval
                        </button>
                    </div>
                    <div className="flex flex-col h-full">
                        <div className="flex flex-row w-full text-white text-sm pt-4">
                            <div className="w-[10%] h-20 flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">Requested By</h2>
                            </div>
                            <div className="w-[15%] flex justify-center items-center bg-[#0d3857] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">Product</h2>
                            </div>
                            <div className="flex flex-col w-[15%]">
                                <div className="h-10 flex flex-row justify-center items-center bg-[#0d3857] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                    <h2>Transmission</h2>
                                </div>
                                <div className="flex flex-row h-10">
                                    <h2 className="w-1/3 text-center bg-[#3d6078] border-solid border-r-2 border-white flex flex-row justify-center items-center">Visible</h2>
                                    <h2 className="w-1/3 text-center bg-[#557489] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                    <h2 className="w-1/3 text-center bg-[#6e889a] border-solid border-r-2 border-white flex flex-row justify-center items-center">UV</h2>
                                </div>
                            </div>
                            <div className="flex flex-col w-[15%]">
                                <div className=" h-10 flex flex-row justify-center items-center bg-[#3b6178] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                    <h2>Reflectance</h2>
                                </div>
                                <div className="flex flex-row h-10">
                                    <h2 className="w-1/3 text-center bg-[#628193] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-Out</h2>
                                    <h2 className="w-1/3 text-center bg-[#7690a0] border-solid border-r-2 border-white flex flex-row justify-center items-center">Vis-In</h2>
                                    <h2 className="w-1/3 text-center bg-[#89a0ae] border-solid border-r-2 border-white flex flex-row justify-center items-center">Solar</h2>
                                </div>
                            </div>
                            <div className="flex flex-col w-[10%]">
                                <div className="h-10 flex flex-row justify-center items-center bg-[#1f6e9e] border-solid border-r-2 border-b-2 border-white group relative cursor-pointer">
                                    <h2>U-value</h2>
                                </div>
                                <div className="flex flex-row h-10">
                                    <h2 className="w-1/2 text-center bg-[#4b8bb1] border-solid border-r-2 border-white flex flex-row justify-center items-center">Winter</h2>
                                    <h2 className="w-1/2 text-center bg-[#6299bb] border-solid border-r-2 border-white flex flex-row justify-center items-center">Summer</h2>
                                </div>
                            </div>
                            <div className="w-[5%] h-20 flex justify-center items-center bg-[#0079c1] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">SC</h2>
                            </div>
                            <div className="w-[5%] h-20 flex justify-center items-center bg-[#128acb] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">SHGC</h2>
                            </div>
                            <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">RHG</h2>
                            </div>
                            <div className="w-[5%] h-20 flex justify-center items-center bg-[#3795ce] border-solid border-r-2 border-white group relative cursor-pointer">
                                <h2 className="text-center">LSG</h2>
                            </div>
                            <div className="w-[15%] h-20 flex justify-center items-center bg-[#3795ce]">
                                <h2 className="text-center">Actions</h2>
                            </div>
                        </div>
                        {tableData.map((item, index) => (
                            <div className="flex flex-row w-full h-auto text-xs">
                                <div className="w-[10%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{item.last_mod_by}</h2>
                                </div>
                                <div className="w-[15%] flex justify-center items-center bg-[#f3f6f7] border-solid border-r-2 border-b-2 border-white whitespace-pre-line">
                                    <h2 className="text-center">{String.raw`${item.description.split(';').join(';\n')}`}</h2>
                                </div>
                                <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(item.Tvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(item.Tsol.toFixed(2))}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center">{(item.Tuv.toFixed(2))}</h2>
                                </div>
                                <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(item.Routvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(item.Rinvis).toFixed(2)}</h2>
                                    <h2 className="w-1/3 h-full flex justify-center items-center text-center">{(item.Routsol).toFixed(2)}</h2>
                                </div>
                                <div className="w-[10%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="w-1/2 h-full flex justify-center items-center text-center border-solid border-r-2 border-white">{(item.UvalCenterWinterIP).toFixed(2)}</h2>
                                    <h2 className="w-1/2 h-full flex justify-center items-center text-center">{(item.UvalCenterSummerIP).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(item.SCSummer).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(item.SHGC).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(item.RelHeatGainIP).toFixed(2)}</h2>
                                </div>
                                <div className="w-[5%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    <h2 className="text-center">{(item.LSG).toFixed(2)}</h2>
                                </div>
                                <div className="w-[15%] flex justify-center items-center bg-[#ebf2f6] border-solid border-r-2 border-b-2 border-white">
                                    {user.role ==='admin' &&
                                        <div className="flex flex-row w-full justify-evenly">
                                            <button type="submit" onClick={() => handleConfirmApproval(index, item.pd_product_id)} aria-label="Confirm" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconConfirm /></button>
                                            <button type="submit" onClick={() => editModalOpenHandler(index)} aria-label="Edit" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconEdit /></button>
                                            <button type="submit" onClick={() => handleDeleteApproval(index, item.pd_product_id)} aria-label="Delete" className="bg-[#047bc2] text-white w-1/4 p-2 rounded flex items-center justify-center"><IconTrash /></button>
                                        </div>
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }

            <ProductEditModal
                open={isEditModalOpen}
                onClose={() => setIsEditModalOpen(false)}
                data={editData}
                updateFunction={modalUpdateFunction}
            />

            <ManuelEntryModal
                open={isManuelEntryModalOpen}
                onClose={() => setIsManuelEntryModalOpen(false)}
                updateFunction={modalManuelEntryUpdateFunction}
            />
        </section>
    )
}

export default ApprovalDashboard;