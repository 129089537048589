import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { GLASS_CONSTANT, GLASS_NAME_CONSTANT } from './GlassConstant';
import { COATING_NAME_CONSTANT } from './CoatingConstant';
import { INTERLAYER_CONSTANT, INTERLAYER_NAME_CONSTANT } from './InterlayerConstant';
import { AIR_CONSTANT, AIR_NAME_CONSTANT } from './AirConstant';
import IconDownArrow from '../Icon/IconDownArrow';
import {PRINT_CONSTANT, PRINT_NAME_CONSTANT} from './PrintConstant';

const SurfaceDropdown = ({
     defaultOption,
     selectType,
     title,
     id,
     surfaceIndex,
     handleFunction,
     handleThicknessFunction,
     extraHandleFunction,
     extraHandleThicknessFunction,
     defaultValue,
     defaultThickness,
     defaultExtraValue,
     defaultExtraThickness,
 }) => {
    const [selectOptions, setSelectOptions] = useState([]);
    const [extraSelectOptions, setExtraSelectOptions] = useState([]);
    const [selectThicknessOptions, setSelectThicknessOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [mainSelectValue, setMainSelectValue] = useState(defaultValue);
    const [extraSelectValue, setExtraSelectValue] = useState('');
    const [thicknessSelectValue, setThicknessSelectValue] = useState(defaultThickness);
    const [extraThicknessSelectValue, setExtraThicknessSelectValue] = useState('');
    const [isPrint, setIsPrint] = useState(false);
    const [isExtraSilkScreen, setIsExtraSilkScreen] = useState(true);

    const handleToggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    function handleExtraTextFieldChange(event) {
        const { value } = event.target.value;
        setExtraThicknessSelectValue(event.target.value);

        if(event.target.value < 0) {
            setExtraThicknessSelectValue(0);
        }

        extraHandleThicknessFunction({
            id,
            value: event.target.value > 0  ? event.target.value : 0,
            surfaceIndex,
            isExtra: isExtraSilkScreen});
    };

    useEffect(() => {
        setExtraSelectOptions(PRINT_NAME_CONSTANT);
        switch (selectType) {
            case 'G':
                setSelectOptions(GLASS_NAME_CONSTANT);
                GLASS_CONSTANT.filter(element => element.name === defaultValue).map((item) => (
                    setSelectThicknessOptions(item.thickness)
                ))
                break;
            case 'A':
                setSelectOptions(AIR_NAME_CONSTANT);
                AIR_CONSTANT.filter(element => element.name === defaultValue).map((item) => (
                    setSelectThicknessOptions(item.thickness)
                ))
                break;
            case 'I':
                setSelectOptions(INTERLAYER_NAME_CONSTANT);
                INTERLAYER_CONSTANT.filter(element => element.name === defaultValue).map((item) => (
                    setSelectThicknessOptions(item.thickness)
                ))
                break;
            case 'S':
                setSelectOptions(COATING_NAME_CONSTANT);
                break;
            default:
                break;
        }
    }, [])

    function getBackgroundColor() {
        switch (selectType) {
            case 'G':
                return '#8FBCD8';
            case 'A':
                return '#DAE3EC';
            case 'I':
                return '#b1c0c9';
            case 'S':
                return '#d4e1f3';
            default:
                break;
        }
    }

    function getThicknessBackgroundColor() {
        switch (selectType) {
            case 'G':
                return '#3291CA';
            case 'I':
                return '#718B97';
            case 'A':
                return '#9DB3BD';
            default:
                break;
        }
    }

    function handleMainSelectChange(event) {
        const { value } = event.target;
        setMainSelectValue(value);

        if(selectType === 'G') {
            setThicknessSelectValue('1/4 - 6mm');
            handleThicknessFunction({
                id,
                value: 0.25,
                surfaceIndex,
                isPrint});
        } else if (selectType === 'I') {
            setThicknessSelectValue('');
            handleThicknessFunction({
                id,
                value: INTERLAYER_CONSTANT.filter(element => element.name === event.target.value)[0].thickness[0],
                surfaceIndex,
                isPrint});
        }  else {
            setThicknessSelectValue('');
            handleThicknessFunction({
                id,
                value: '',
                surfaceIndex,
                isPrint});
        }

        let printValue = '';
        if(isPrint) {
            const matchedElement = PRINT_CONSTANT.find(element => element.name === event.target.value);
            printValue = matchedElement.code;
        }

        handleFunction({
            id,
            value: isPrint ? printValue : event.target.value,
            surfaceIndex});

        if(selectType === 'G') {
            GLASS_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else if (selectType === 'I') {
            INTERLAYER_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else if (selectType === 'A') {
            AIR_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else {
            handleThicknessFunction({
                id,
                value: 100,
                surfaceIndex,
                isPrint});
        }
    }

    function handleExtraSelectChange(event) {
        const { value } = event.target;
        setExtraSelectValue(value);

        let printValue = '';
        const matchedElement = PRINT_CONSTANT.find(element => element.name === event.target.value);
        printValue = matchedElement.code;

        extraHandleFunction({
            id,
            value: printValue,
            surfaceIndex});

        if(selectType === 'G') {
            GLASS_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else if (selectType === 'I') {
            INTERLAYER_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else if (selectType === 'A') {
            AIR_CONSTANT.filter(element => element.name === event.target.value).map((item) => (
                setSelectThicknessOptions(item.thickness)
            ))
        } else {
            extraHandleThicknessFunction({
                id,
                value: extraThicknessSelectValue !== '' ? extraThicknessSelectValue : 100,
                surfaceIndex,
                isExtra: isExtraSilkScreen});
        }
    }

    function handleThicknessSelectChange(event) {
        const { value } = event.target;
        setThicknessSelectValue(value);
        let nominalThickness = event.target.value;

        if (selectType !== 'I') {

            switch (value){
                case '1/8 - 3mm':
                    nominalThickness = 0.125;
                    break;
                case '5/32 - 4mm':
                    nominalThickness = 0.15625;
                    break;
                case  '3/16 - 5mm':
                    nominalThickness = 0.1875;
                    break;
                case  '1/4 - 6mm':
                    nominalThickness = 0.25;
                    break;
                case '5/16 - 8mm':
                    nominalThickness = 0.3125;
                    break;
                case '5/16 - 7.5mm':
                    nominalThickness = 0.3125;
                    break;
                case '3/8 - 10mm':
                    nominalThickness = 0.375;
                    break;
                case '3/8 - 9mm':
                    nominalThickness = 0.375;
                    break;
                case '7/16 - 11mm':
                    nominalThickness = 0.4375;
                    break;
                case '15/32 - 11.9mm':
                    nominalThickness = 0.46875;
                    break;
                case '15/32 - 12mm':
                    nominalThickness = 0.46875;
                    break;
                case '1/2 - 12mm':
                    nominalThickness = 0.5;
                    break;
                case '1/2 - 13.2mm':
                    nominalThickness = 0.5;
                    break;
                case '9/16 - 14.2mm':
                    nominalThickness = 0.5625;
                    break;
                case '9/16 - 14mm':
                    nominalThickness = 0.5625;
                    break;
                case '5/8 - 15.5mm':
                    nominalThickness = 0.625;
                    break;
                case '23/32 - 18.2mm':
                    nominalThickness = 0.71875;
                    break;
                case '23/32 - 18mm':
                    nominalThickness = 0.71875;
                    break;
                case '3/4 - 18.5mm':
                    nominalThickness = 0.75;
                    break;
                case '7/8 - 22.2mm':
                    nominalThickness = 0.875;
                    break;
                case '7/8 - 22mm':
                    nominalThickness = 0.875;
                    break;
                case '1 - 25.4mm':
                    nominalThickness = 1;
                    break;
                default:
                    nominalThickness = 0.25;
                    break;
            }
        }

        handleThicknessFunction({
            id,
            value: nominalThickness,
            surfaceIndex,
            isPrint});
    }

    return (
        <div
            style={{ backgroundColor: getBackgroundColor() }}
            className="flex flex-col m-1 p-2 rounded"
        >
            {selectType !== 'A' &&
                <button
                    type="button"
                    className="flex flex-row pl-1 pb-1 font-normal"
                    onClick={handleToggleAccordion}
                >
                    <div className="flex flex-row justify-between w-full">
                        <h2>{title}</h2>
                        <IconDownArrow />
                    </div>
                </button>
            }
            {(isOpen && (selectType === 'G' || selectType === 'I')) && (
                <div className="flex flex-row">
                    <div className="flex flex-col w-2/3 pr-4">
                        <select
                            value={mainSelectValue}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            onChange={handleMainSelectChange}
                        >
                            <option value="">{defaultOption}</option>
                            {selectOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-col w-1/3">
                        <select
                            value={thicknessSelectValue}
                            className="border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            onChange={handleThicknessSelectChange}
                            style={{backgroundColor: getThicknessBackgroundColor()}}
                        >
                            {selectThicknessOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            )}
            {selectType === 'A' &&
                <div className="flex flex-row">
                    <div className="flex flex-col w-2/3 pr-4">
                        <select
                            value={mainSelectValue}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            onChange={handleMainSelectChange}
                        >
                            {selectOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex flex-col w-1/3">
                        <select
                            value={thicknessSelectValue}
                            className="border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                            onChange={handleThicknessSelectChange}
                            style={{backgroundColor: getThicknessBackgroundColor()}}
                        >
                            {selectThicknessOptions.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            }
            {isOpen && (selectType === 'S') && (
                <div className="flex flex-row pr-4 items-center justify-center">
                    {!isPrint &&
                        <div className="flex flex-col w-full">
                            <div className="flex flex-row w-full">
                                <div className="flex flex-col w-3/5">
                                    <select
                                        value={mainSelectValue}
                                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                        onChange={handleMainSelectChange}
                                    >
                                        {selectOptions.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            {isExtraSilkScreen &&
                                <div className="flex flex-row w-full mt-2">
                                    <div className="flex flex-col w-3/5">
                                        <select
                                            value={extraSelectValue}
                                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            onChange={handleExtraSelectChange}
                                        >
                                            {extraSelectOptions.map((item) => (
                                                <option key={item} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="flex flex-col w-2/5 pl-4">
                                        <input
                                            type="number"
                                            min="0"
                                            className="w-full h-full text-center rounded text-sm"
                                            placeholder="Coverage %"
                                            onChange={handleExtraTextFieldChange}
                                            value={defaultExtraThickness !== '' ? defaultExtraThickness : extraThicknessSelectValue}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            )}
        </div>
    );
}

SurfaceDropdown.propTypes = {
    defaultOption: PropTypes.string.isRequired,
    selectType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    surfaceIndex: PropTypes.number.isRequired,
    handleFunction: PropTypes.func.isRequired,
    handleThicknessFunction: PropTypes.func.isRequired,
    extraHandleFunction: PropTypes.func,
    extraHandleThicknessFunction: PropTypes.func,
    defaultValue: PropTypes.string,
    defaultThickness: PropTypes.string,
    defaultExtraValue: PropTypes.string,
    defaultExtraThickness: PropTypes.string,
}

SurfaceDropdown.defaultProps = {
    extraHandleFunction:  () => undefined,
    extraHandleThicknessFunction:  () => undefined,
    defaultValue: '',
    defaultThickness: '',
    defaultExtraValue: '',
    defaultExtraThickness: '',
};

export default SurfaceDropdown;